<template>
  <button
    type="button"
    class="button button-upload-file"
    tabindex="-1"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <b-icon icon="paperclip" size="is-small" />
    <span>Attach file(s)</span>
  </button>
</template>

<script>
export default {
  name: "PostAttachFilesButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
